
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import GridLayout from "../../components/GridLayout"
import PageLayout from "../../components/PageLayout"
import GridTextProjectElement from "../../components/GridTextProjectElement"
import { projectNames } from "../../context/consts"
import Seo from "../../components/Seo"


const fightechGlovePage = () => {

    const imageComponents = [
        <StaticImage key={1} src="./../../images/fightech/fightech-0.jpg" alt="" layout="fullWidth"></StaticImage>,
        <GridTextProjectElement key={2} projectName={projectNames.FIGTECH} />,
        <StaticImage key={3} src="./../../images/fightech/fightech-1.jpg" alt="" layout="fullWidth"></StaticImage>,
        <StaticImage key={4} src="./../../images/fightech/fightech-2.jpg" alt="" layout="fullWidth"></StaticImage>,
        <StaticImage key={5} src="./../../images/fightech/fightech-3.jpg" alt="" layout="fullWidth"></StaticImage>,
        <StaticImage key={6} src="./../../images/fightech/fightech-4.jpg" alt="" layout="fullWidth"></StaticImage>,
    ]

    return (
        <PageLayout firstHead="fightech" secondHead="underglove">
            <GridLayout components={imageComponents}></GridLayout>
            <Seo subTitle="fightech underglove" />
        </PageLayout>
    )

}

export default fightechGlovePage